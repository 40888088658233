import React, { useEffect, useState } from 'react'
import { Button, message, DatePicker, Form, Input, Space } from 'antd'
import dayjs from 'dayjs'
import { useNavigate, useLocation } from 'react-router-dom'
import queryString from 'query-string'
import weekday from 'dayjs/plugin/weekday'

import request from 'utils/request'

import styles from './index.module.scss'

const layout = {
    labelCol: {
        span: 3
    },
    wrapperCol: {
        span: 12
    }
}

const PrivateDomainMerchantsDetail = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const [messageApi, contextHolder] = message.useMessage()
    const [form] = Form.useForm()
    const [initialState, setInitialState] = useState({})

    // 获取查询参数
    const queryParams = queryString.parse(location.search) || {}

    const onFinish = async (values) => {
        try {
            form.validateFields()
            const formattedValues = {
                ...queryParams,
                ...values,
                startDate: values.startDate ? dayjs(values.startDate).valueOf() : null,
                endDate: values.endDate ? dayjs(values.endDate).valueOf() : null
            }

            const res = await request('admin/privateDomain/merchants/' + (queryParams.id ? 'update' : 'create'), { data: formattedValues })

            const { statusCode } = res
            if (statusCode === 200) {
                messageApi.success('提交失败')
                navigate(-1, { state: { refresh: true } })
            } else {
                messageApi.error(res?.message?.[0] || '提交失败')
            }
        } catch {}
    }

    const getInitData = async (id) => {
        try {
            const res = await request('admin/privateDomain/merchants/' + id, {
                method: 'GET'
            })

            const { statusCode, data } = res
            if (statusCode === 200) {
                // 将日期字符串转换为 dayjs 对象
                const formattedData = {
                    ...data,
                    startDate: !!data?.startDate ? dayjs(data.startDate) : null,
                    endDate: !!data?.endDate ? dayjs(data.endDate) : null
                }
                setInitialState(formattedData)
                form.setFieldsValue(formattedData) // 更新表单的值
            }
        } catch {}
    }

    const statusText = (text) => (text === 'active' ? '待服务' : text === 'pending' ? '服务中' : text === 'expired' ? '已过期' : text === 'terminated' ? '已禁用' : '')

    useEffect(() => {
        if (queryParams?.id) getInitData(queryParams.id)

        return () => {
            setInitialState({})
            form.resetFields()
        }
    }, [location])

    return (
        <>
            {contextHolder}
            <div className={styles.header}>
                <p className={styles.title}>{!!queryParams?.id ? `商户ID: ${queryParams.id}` : '新建商户'}</p>
                {!!initialState?.status && <p style={{ color: '#1677ff', marginLeft: 10 }}>({statusText(initialState.status)})</p>}
            </div>

            <Form form={form} initialValues={initialState} {...layout} style={{ marginBottom: 20 }} onFinish={onFinish}>
                <Form.Item
                    name="name"
                    label="商户名称"
                    rules={[
                        {
                            required: true,
                            message: '请输入商户名称!'
                        }
                    ]}>
                    <Input placeholder="请输入商户名称" />
                </Form.Item>

                <Form.Item
                    name="brandName"
                    label="品牌名称"
                    rules={[
                        {
                            required: true,
                            message: '请输入品牌名称!'
                        }
                    ]}>
                    <Input placeholder="请输入品牌名称" />
                </Form.Item>

                <Form.Item
                    name="appid"
                    label="appid"
                    rules={[
                        {
                            required: true,
                            message: '请输入appid!'
                        }
                    ]}>
                    <Input placeholder="请输入appid" />
                </Form.Item>

                <Form.Item
                    name="secret"
                    label="secret"
                    rules={[
                        {
                            required: true,
                            message: '请输入secret!'
                        }
                    ]}>
                    <Input placeholder="请输入secret" />
                </Form.Item>

                <Form.Item name="contract" label="协议链接">
                    <Input placeholder="请输入协议链接" />
                </Form.Item>

                <Form.Item
                    name="phone"
                    label="联系方式"
                    rules={[
                        {
                            required: true,
                            message: '请输入联系方式!'
                        }
                    ]}>
                    <Input placeholder="请输入联系方式" />
                </Form.Item>

                <Form.Item
                    name="email"
                    label="邮箱"
                    rules={[
                        {
                            required: true,
                            message: '请输入邮箱!'
                        }
                    ]}>
                    <Input placeholder="请输入邮箱" />
                </Form.Item>

                <Form.Item name="remark" label="备注">
                    <Input placeholder="备注" />
                </Form.Item>

                <Form.Item name="startDate" label="协议开始时间">
                    <DatePicker style={{ width: '100%' }} placeholder="请输入协议开始时间" />
                </Form.Item>

                <Form.Item name="endDate" label="协议结束时间">
                    <DatePicker style={{ width: '100%' }} placeholder="请输入协议结束时间" />
                </Form.Item>

                <Form.Item>
                    <Space>
                        <Button type="primary" htmlType="submit">
                            提交
                        </Button>
                        <Button htmlType="button" onClick={() => form.resetFields()}>
                            重置
                        </Button>
                        <Button htmlType="button" onClick={() => navigate(-1)}>
                            返回
                        </Button>
                    </Space>
                </Form.Item>
            </Form>
        </>
    )
}

export default PrivateDomainMerchantsDetail
