import { CoffeeOutlined, UserOutlined, HomeOutlined, ShoppingOutlined } from '@ant-design/icons'

const menuItems = [
    {
        key: '',
        icon: <CoffeeOutlined />,
        label: '欢迎'
    },
    {
        key: 'index',
        icon: <HomeOutlined />,
        label: '官网配置',
        children: [
            {
                key: 'include',
                label: '收录信息'
            }
        ]
    },
    {
        key: 'account',
        icon: <UserOutlined />,
        label: '账号管理',
        children: [
            {
                key: 'audit',
                label: '待审核账号'
            },
            {
                key: 'info',
                label: '账号信息'
            }
        ]
    },
    {
        key: 'privateDomain',
        icon: <ShoppingOutlined />,
        label: '私域小程序',
        children: [
            {
                key: 'merchants',
                label: '商户列表'
            },
            {
                key: 'users',
                label: '用户列表'
            }
        ]
    }
]

export default menuItems
