import React from 'react'
import { Layout } from 'antd'

import UserInfo from './UserInfo'

import styles from '../index.module.scss'

const { Header } = Layout

const rightStyle = {
    display: 'flex',
    justifyContent: 'end-flex',
    alignItems: 'center'
}

const Components = () => {
    return (
        <Header className={styles.header}>
            <div style={rightStyle}>
                <UserInfo />
            </div>
        </Header>
    )
}

export default Components
