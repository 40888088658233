import { useState } from 'react'
import { Button, Form, Input, Divider, message } from 'antd'
import { useNavigate } from 'react-router-dom'

import request from 'utils/request'

import styles from './index.module.scss'

const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 }
}

const Forget = () => {
    const navigate = useNavigate()
    const [form] = Form.useForm()
    const [messageApi, contextHolder] = message.useMessage()
    const [sendText, setSendText] = useState('发送验证码')
    let countdownNum = 60

    // 倒计时
    const countdown = () => {
        setTimeout(() => {
            if (countdownNum > 0) {
                countdownNum--
                setSendText(`重新发送(${countdownNum}秒)`)
                countdown()
            } else {
                countdownNum = 60
                setSendText('重新发送')
            }
        }, 1000)
    }

    // 发送验证码
    const send = async () => {
        try {
            if (sendText !== '发送验证码' && sendText !== '重新发送') {
                messageApi.warning('别急别急～')
                return
            }

            const phone = form.getFieldValue('phone')

            if (!phone) {
                messageApi.error('请先输入手机号')
                return
            }

            const res = await request('common/sendSmsCode', { method: 'GET', params: { phone } })

            if (res.statusCode === 200) {
                // 倒计时
                countdown()

                // 测试操作
                form.setFieldValue('code', +res.data)
            } else {
                messageApi.error(res?.message?.[0] || '发送失败')
            }
        } catch (err) {
            console.log(err)
        }
    }

    // 提交注册
    const onFinish = async (value) => {
        try {
            const res = await request('admin/forget', { data: value })

            if (res.statusCode === 200) {
                message.success('密码修改成功')
                navigate('/login')
            } else {
                message.error(res?.message?.[0] || '密码修改失败')
            }
        } catch (err) {
            console.log(err)
        }
    }

    return (
        <>
            {contextHolder}
            <div className={styles.view}>
                <p className={styles.desc}>Forgot your password again?</p>
                <Divider />
                <Form form={form} className={styles.form} {...layout} onFinish={onFinish}>
                    <Form.Item
                        label="手机号"
                        name="phone"
                        rules={[
                            {
                                required: true,
                                message: '请输入您的手机号'
                            }
                        ]}>
                        <Input placeholder="请输入您手机号" />
                    </Form.Item>

                    <Form.Item
                        label="新密码"
                        name="password"
                        rules={[
                            {
                                required: true,
                                message: '请输入你的密码'
                            }
                        ]}>
                        <Input.Password placeholder="请输入你的密码" />
                    </Form.Item>

                    <Form.Item
                        label="再次输入密码"
                        name="twoPassword"
                        rules={[
                            {
                                required: true,
                                message: '请输入你的密码'
                            }
                        ]}>
                        <Input.Password placeholder="请输入你的密码" />
                    </Form.Item>

                    <Form.Item
                        label="验证码"
                        name="code"
                        rules={[
                            {
                                required: true,
                                message: '请输入验证码'
                            }
                        ]}>
                        <Input
                            placeholder="请输入验证码"
                            className={styles.inputNumber}
                            addonAfter={
                                <div className={styles.send} onClick={() => send()}>
                                    {sendText}
                                </div>
                            }
                            controls={false}
                        />
                    </Form.Item>

                    <div className={styles.btns}>
                        <Button type="primary" className={styles.btn} htmlType="submit">
                            提交修改
                        </Button>
                    </div>
                </Form>
            </div>
        </>
    )
}

export default Forget
