import axios from 'axios'

console.log(process.env)

axios.interceptors.response.use(
    (res) => res.data,
    (err) => (err?.response?.data ? Promise.resolve(err.response.data) : Promise.reject(err)) // 请求报错有返回的值，就走resolve
)

axios.defaults.withCredentials = true
axios.defaults.baseURL = process.env.NODE_ENV === 'development' ? 'https://dev.muyuxiao.cn/api/' : 'https://muyuxiao.cn/api/'

const request = (path, config = {}) => {
    try {
        return axios.request({
            url: path,
            method: 'POST',
            ...config
        })
    } catch (err) {
        console.log(12313321)
        console.log(err)
    }
}

export default request
