import React from 'react'
import { Layout, Menu } from 'antd'
import { useNavigate } from 'react-router-dom'

import items from '../menu'

import styles from '../index.module.scss'

const { Sider } = Layout

const Components = () => {
    const navigate = useNavigate()

    const onClick = (e) => {
        console.log(e)
        navigate(e.keyPath.reverse().join('/'))
    }

    // console.log('items', items)

    return (
        <Sider className={styles.sider}>
            <div className={styles.logo}>
                <img src="https://cos.muyuxiao.cn/newLogo.png" />
                <p>木雨科技</p>
            </div>
            <Menu className={styles.menu} onClick={onClick} mode="inline" items={items} />
        </Sider>
    )
}

export default Components
