import React, { useEffect, useState } from 'react'
import { Button, Table, message, Form, Input, Popconfirm } from 'antd'
import dayjs from 'dayjs'
import { useNavigate, useLocation } from 'react-router-dom'

import request from 'utils/request'

import styles from './index.module.scss'

const PrivateDomainMerchants = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const [form] = Form.useForm()
    const [messageApi, contextHolder] = message.useMessage()

    const [dataSource, setDataSource] = useState([])
    const [loading, setLoading] = useState(false)
    const [page, setPage] = useState(1)
    const [size, setSize] = useState(10)
    const [total, setTotal] = useState(0)

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            width: 60
        },
        {
            title: '名称',
            dataIndex: 'name',
            key: 'name',
            render: (text, row) => <a>{text + (!!row?.brandName ? `(${row.brandName})` : '')}</a>
        },
        {
            title: 'appid',
            dataIndex: 'appid',
            key: 'appid'
        },
        {
            title: '状态',
            dataIndex: 'status',
            key: 'status',
            width: 120,
            render: (text) => {
                return text === 'active' ? '待服务' : text === 'pending' ? '服务中' : text === 'expired' ? '已过期' : text === 'terminated' ? '已禁用' : ''
            }
        },
        {
            title: '最近更新',
            dataIndex: 'updateDate',
            key: 'updateDate',
            width: 210,
            render: (text) => dayjs(text).format('YYYY-MM-DD HH:mm:ss')
        },
        {
            title: '操作',
            width: 200,
            render: (_, rows) => (
                <>
                    <a onClick={() => navigate('/privateDomain/merchants/detail?id=' + rows.id)}>编辑</a>
                    {rows.status === 'pending' && (
                        <Popconfirm placement="left" title="是否需要停止该商户的服务?" onConfirm={() => handleStatus(rows.id, 'terminated')}>
                            <a style={{ color: '#ff4d4f', marginLeft: 10 }}>终止服务</a>
                        </Popconfirm>
                    )}

                    {rows.status === 'expired' ||
                        (rows.status === 'terminated' && (
                            <Popconfirm placement="left" title="是否需要停止该商户的服务?" onConfirm={() => handleStatus(rows.id, 'pending')}>
                                <a style={{ color: '#52c41a', marginLeft: 10 }}>开启服务</a>
                            </Popconfirm>
                        ))}
                </>
            )
        }
    ]

    const handleStatus = async (id, path) => {
        if (!path) return
        try {
            const res = await request(`admin/privateDomain/merchants/${path}`, { method: 'POST', data: { id } })
            const { statusCode, message } = res
            if (statusCode === 200) {
                messageApi.success('操作成功')
                getList()
            } else {
                messageApi.error(message || '操作失败')
            }
        } catch (err) {
            console.log(err)
            messageApi.error(err || '操作失败')
        }
    }

    const getList = async (values) => {
        try {
            setLoading(true)
            const res = await request('admin/privateDomain/merchants', {
                method: 'POST',
                data: {
                    currentPage: page,
                    pageSize: size,
                    ...values
                }
            })

            const {
                statusCode,
                data,
                page: { currentPage, pageSize, total }
            } = res

            if (statusCode === 200) {
                setDataSource(data)
                setPage(currentPage)
                setSize(pageSize)
                setTotal(total)
            } else {
                messageApi.error(res?.message?.[0] || '获取商户列表失败')
            }
        } catch (err) {
            console.log(err)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        getList()
    }, [location])

    return (
        <>
            {contextHolder}
            <Form
                form={form}
                className={styles.form}
                initialValues={{}}
                layout="inline"
                onValuesChange={(_, values) => {
                    getList(values)
                }}>
                <Form.Item label="关键词" name="keyword">
                    <Input className={styles.inputNumber} placeholder="可输入标题" />
                </Form.Item>
                <Form.Item>
                    <Button
                        style={{ marginRight: 20 }}
                        onClick={() => {
                            form.resetFields()
                        }}>
                        重置
                    </Button>
                    <Button type="primary" onClick={() => navigate('/privateDomain/merchants/detail')}>
                        新建商户
                    </Button>
                </Form.Item>
            </Form>

            <Table
                rowKey={'id'}
                loading={loading}
                dataSource={dataSource}
                columns={columns}
                pagination={{
                    showSizeChanger: true,
                    showQuickJumper: true,
                    pageSizeOptions: [5, 10, 20, 50],
                    current: page,
                    pageSize: size,
                    total: total,
                    showTotal: (total) => `总数: ${total} 项`,
                    onChange: (current, size) => {
                        setSize(size)
                        setPage(current)
                    }
                }}
            />
        </>
    )
}

export default PrivateDomainMerchants
