import React, { useState, useEffect } from 'react'
import { Button, Form, InputNumber, Table, message, Image, Modal, Input } from 'antd'
import dayjs from 'dayjs'

import request from 'utils/request'

import styles from './index.module.scss'

const { TextArea } = Input

const AccountAudit = () => {
    const [messageApi, contextHolder] = message.useMessage()
    const [loading, setLoading] = useState(false)
    const [dataSource, setDataSource] = useState([])
    const [currentPage, setCurrentPage] = useState(1)
    const [pageSize, setPageSize] = useState(10)
    const [total, setTotal] = useState(0)
    const [rejectId, setRejectId] = useState(NaN)
    const columns = [
        {
            title: '头像',
            dataIndex: 'avatar',
            render: (v) => {
                return <Image className={styles.avatar} width={42} src={v} />
            }
        },
        {
            title: '姓名',
            dataIndex: 'name'
        },
        {
            title: '手机号',
            dataIndex: 'phone'
        },
        {
            title: '权限身份',
            dataIndex: 'roles',
            render: (v) => {
                if (v === 1) return '超级管理员'
                if (v === 2) return '协作者'
                if (v === 3) return '数据观察员'
                return '-'
            }
        },
        {
            title: '提交时间',
            dataIndex: 'createDate',
            render: (v) => {
                return dayjs(v).format('YYYY-MM-DD HH:mm:ss')
            }
        },
        {
            title: '操作',
            width: 180,
            render: (rows) => {
                return (
                    <>
                        <Button type="link" style={{ marginRight: 10, color: '#ffd13d', fontWeight: 600 }} onClick={() => status(1, rows.id)}>
                            通过
                        </Button>
                        <Button type="link" danger onClick={() => setRejectId(rows.id)}>
                            拒绝
                        </Button>
                    </>
                )
            }
        }
    ]

    const status = async (s, id) => {
        try {
            if (!status) {
                messageApi.error('操作失败')
                return
            }

            const res = await request('admin/setStatus', { data: { id, status: s } })

            if (res.statusCode === 200) {
                s === 2 && setRejectId(NaN)
                messageApi.success('操作成功')
                pendingList(currentPage, pageSize)
            } else {
                message.error(res?.message || '操作失败')
            }
        } catch (err) {
            console.log(err)
        }
    }

    // 获取列表
    const pendingList = async (cPage, pSize) => {
        try {
            setLoading(true)
            const res = await request('admin/getPendingList', {
                method: 'GET',
                data: {
                    currentPage: cPage || currentPage,
                    pageSize: pSize || pageSize
                }
            })
            if (res.statusCode === 200) {
                Array.isArray(res?.data) && setDataSource(res.data)

                setCurrentPage(res.page.currentPage)
                setPageSize(res.page.cpageSize)
                setTotal(res.page.ctotal)
            } else {
                messageApi.error('asd')
            }
        } catch (err) {
            console.log(err)
        }

        setLoading(false)
    }

    useEffect(() => {
        pendingList()
    }, [])

    return (
        <>
            {contextHolder}
            <div>
                <Form className={styles.form} layout="inline">
                    <Form.Item label="手机号">
                        <InputNumber className={styles.inputNumber} placeholder="可输入手机号" />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary">查找</Button>
                    </Form.Item>
                </Form>

                <Table
                    rowKey={'id'}
                    loading={loading}
                    dataSource={dataSource}
                    columns={columns}
                    pagination={{
                        showSizeChanger: true,
                        current: currentPage,
                        pageSize,
                        total,
                        onChange: (page) => pendingList(page),
                        onShowSizeChange: (current, size) => pendingList(1, size)
                    }}
                />
            </div>

            <Modal title="拒绝申请" open={!isNaN(rejectId)} onOk={() => status(2, rejectId)} onCancel={() => setRejectId(NaN)}>
                <Form layout="vertical">
                    <Form.Item label="拒绝理由" name="remark">
                        <TextArea placeholder="请输入拒绝理由" />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}

export default AccountAudit
