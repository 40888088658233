export const getImageDimensions = (src) => {
    return new Promise((resolve, reject) => {
        const img = new Image()
        img.src = src
        img.onload = () => {
            const width = img.naturalWidth
            const height = img.naturalHeight
            resolve({ width, height })
        }
        img.onerror = () => {
            reject(new Error('Failed to load image'))
        }
    })
}

// 递归菜单
export function getAllMenuItems(menu) {
    let result = {}

    menu.forEach((item) => {
        result[item.key] = item.label

        if (item.children) {
            result = { ...result, ...getAllMenuItems(item.children) } // 递归处理子项并合并结果
        }
    })

    return result
}
