import React, { useEffect, useState } from 'react'
import { Layout, Card, Spin, Breadcrumb } from 'antd'
import { Outlet } from 'react-router-dom'
import { useNavigate, useLocation } from 'react-router-dom'
import { LoadingOutlined } from '@ant-design/icons'
import { useAtom } from 'jotai'

import KeepAlive from 'components/KeepAlive'
import Header from './components/Header'
import Sider from './components/Sider'
import { adminInfoAtom, breadcrumbAtom } from 'store'
import { getAllMenuItems } from 'utils'
import menuItems from './menu'
import request from 'utils/request'

import styles from './index.module.scss'

const { Content } = Layout

const View = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const [, setAdminInfo] = useAtom(adminInfoAtom)
    const [items, setItems] = useAtom(breadcrumbAtom)
    const [spinning, setSpinning] = useState(true)

    // 获取用户信息
    const getAdmin = async () => {
        try {
            const res = await request('admin/getInfo', { method: 'GET' })
            if (res.statusCode === 200 && res.data) {
                setAdminInfo(res.data)
                setSpinning(false)
            } else {
                // 获取不到用户信息，跳转去登录页面
                navigate('/login?redirectUrl=' + encodeURIComponent(window.location.pathname))
            }
        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        getAdmin()
    }, [])

    useEffect(() => {
        const menus = getAllMenuItems(menuItems)
        setItems(
            location.pathname
                .substring(1)
                .split('/')
                .map((text, i) => ({
                    title: menus[text] || (text === 'detail' ? '详情' : '首页')
                }))
        )
    }, [location])

    return (
        <>
            {!spinning && (
                <Layout className={styles.layout}>
                    <Sider />
                    <Layout>
                        <Header />
                        <Content className={styles.content}>
                            <Breadcrumb items={items} />;
                            <Card>
                                <KeepAlive>
                                    <Outlet />
                                </KeepAlive>
                            </Card>
                        </Content>
                    </Layout>
                </Layout>
            )}
            <Spin spinning={spinning} indicator={<LoadingOutlined style={{ fontSize: 36 }} spin />} />
        </>
    )
}

export default View
