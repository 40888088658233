import React, { useEffect, useState } from 'react'
import { Button, Table, message, Form, Input, Popconfirm, Select } from 'antd'
import { useLocation } from 'react-router-dom'

import request from 'utils/request'

import styles from './index.module.scss'

const PrivateDomainMerchants = () => {
    const location = useLocation()
    const [form] = Form.useForm()
    const [messageApi, contextHolder] = message.useMessage()

    const [dataSource, setDataSource] = useState([])
    const [loading, setLoading] = useState(false)
    const [currentPage, setCurrentPage] = useState(1)
    const [size, setSize] = useState(10)
    const [total, setTotal] = useState(0)

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            width: 60
        },
        {
            title: '商户ID',
            dataIndex: 'merchantsId',
            width: 100
        },
        {
            title: '昵称',
            dataIndex: 'nickname'
        },
        {
            title: 'openid',
            dataIndex: 'openid',
            key: 'openid'
        },
        {
            title: '身份',
            dataIndex: 'identity',
            width: 120,
            render: (text) => {
                return text === 'admin' ? '管理员' : text === 'staff' ? '商户员工' : '用户'
            }
        },
        {
            title: '操作',
            width: 150,
            render: (_, rows) => (
                <>
                    {rows.identity === 'visitors' && (
                        <Popconfirm placement="left" title="是否该用户升级管理身份(会覆盖原有管理员身份)?" onConfirm={() => handleSetAdmin(rows)}>
                            <a style={{ color: '#ff4d4f', marginLeft: 10 }}>设置管理员</a>
                        </Popconfirm>
                    )}
                </>
            )
        }
    ]

    const getList = async (values) => {
        try {
            setLoading(true)
            const res = await request('admin/privateDomain/users', {
                method: 'POST',
                data: {
                    currentPage,
                    pageSize: size,
                    ...values
                }
            })

            const { statusCode, data, page } = res

            if (statusCode === 200) {
                setDataSource(data)
                setCurrentPage(page.currentPage)
                setSize(page.pageSize)
                setTotal(page.total)
            } else {
                messageApi.error(res?.message?.[0] || '获取商户列表失败')
            }
        } catch (err) {
            console.log(err)
        } finally {
            setLoading(false)
        }
    }

    const handleSetAdmin = async ({ merchantsId, id, openid }) => {
        try {
            const res = await request('admin/privateDomain/setAdmin', { data: { merchantsId, userId: id, openid } })
            const { statusCode, message } = res

            if (statusCode === 200) {
                messageApi.success('设置成功')
                getList()
            } else {
                messageApi.error(message || '设置失败')
            }
        } catch {
            messageApi.error('设置失败')
        }
    }

    useEffect(() => {
        getList()
    }, [location])

    return (
        <>
            {contextHolder}
            <Form
                form={form}
                className={styles.form}
                initialValues={{}}
                layout="inline"
                onValuesChange={(_, values) => {
                    getList(values)
                }}>
                <Form.Item label="昵称" name="nickname">
                    <Input className={styles.inputNumber} allowClear placeholder="可输入名称查询" />
                </Form.Item>
                <Form.Item label="商户ID" name="merchantsId">
                    <Input className={styles.inputNumber} allowClear placeholder="可输入商户ID查询" />
                </Form.Item>
                <Form.Item label="身份" name="identity">
                    <Select style={{ width: 200, textAlign: 'left' }} allowClear placeholder="可选择身份查询">
                        <Select.Option value="admin">管理员</Select.Option>
                        <Select.Option value="staff">员工</Select.Option>
                        <Select.Option value="visitors">用户</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item>
                    <Button
                        style={{ marginRight: 20 }}
                        onClick={() => {
                            form.resetFields()
                        }}>
                        重置
                    </Button>
                </Form.Item>
            </Form>

            <Table
                rowKey={'id'}
                loading={loading}
                dataSource={dataSource}
                columns={columns}
                pagination={{
                    showSizeChanger: true,
                    showQuickJumper: true,
                    pageSizeOptions: [5, 10, 20, 50],
                    current: currentPage,
                    pageSize: size,
                    total: total,
                    showTotal: (total) => `总数: ${total} 项`,
                    onChange: (current, size) => {
                        setSize(size)
                        setCurrentPage(current)
                    }
                }}
            />
        </>
    )
}

export default PrivateDomainMerchants
