import React, { useState, useEffect, useRef } from 'react'
import { Button, Table, message, Select, Form, Input, Popconfirm } from 'antd'
import dayjs from 'dayjs'

import request from 'utils/request'
import IndexDetail from './components/IndexDetail'

import styles from './index.module.scss'

const IndexInclude = () => {
    const detailRef = useRef()
    const [form] = Form.useForm()
    const [messageApi, contextHolder] = message.useMessage()
    const [dataSource, setDataSource] = useState([])
    const [loading, setLoading] = useState(false)

    const [currentPage, setCurrentPage] = useState(1)
    const [pageSize, setPageSize] = useState(10)
    const [total, setTotal] = useState(0)
    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            width: 60
        },
        {
            title: '标题',
            dataIndex: 'title',
            key: 'title'
        },
        {
            title: '栏目',
            dataIndex: 'category',
            key: 'category',
            render: (e) => <>{e === 'inspiration' ? '灵感碎片' : e === 'tools' ? '各种工具' : e === 'study' ? '学习资源' : e === 'recommend' ? '个人推荐' : ''}</>
        },
        {
            title: '分类',
            dataIndex: 'type',
            key: 'type',
            render: (e, rows) => (
                <>
                    {rows.category === 'tools' ? (e === 1 ? '设计工具' : e === 2 ? '研发工具' : e === 3 ? '格式工具' : '') : ''}
                    {rows.category === 'recommend' ? (e === 1 ? '书籍推荐' : e === 2 ? '影视推荐' : '') : ''}
                </>
            )
        },
        {
            title: '更新时间',
            dataIndex: 'updateDate',
            key: 'updateDate',
            width: 200,
            render: (e) => <>{dayjs(e).format('YYYY-MM-DD HH:mm:ss')}</>
        },
        {
            title: '操作',
            width: 120,
            render: (_, rows) => (
                <>
                    <a style={{ marginRight: 10 }} onClick={() => detailRef.current.open(rows)}>
                        编辑
                    </a>
                    <Popconfirm placement="left" title="确认删除此项吗?" onConfirm={() => handleDelete(rows.id)}>
                        <a style={{ color: '#ff4d4f' }}>删除</a>
                    </Popconfirm>
                </>
            )
        }
    ]

    const getList = async (where = {}) => {
        try {
            setLoading(() => true)
            const res = await request('index/list', {
                method: 'POST',
                data: {
                    ...where,
                    currentPage,
                    pageSize
                }
            })

            if (res.statusCode === 200) {
                const { data = [], page = {} } = res
                setDataSource(data)

                setCurrentPage(page.currentPage)
                setPageSize(page.pageSize)
                setTotal(page.total)
            }
        } catch (err) {
            messageApi.error('获取失败')
        }
        setLoading(() => false)
    }

    const handleDelete = async (id) => {
        try {
            const res = await request('index/delete', { method: 'POST', data: { id } })

            if (res.statusCode === 200) {
                messageApi.success('删除成功')
                getList()
            } else {
                messageApi.error('删除失败')
            }
        } catch (err) {
            console.log(err)
            messageApi.error('删除失败')
        }
    }

    useEffect(() => {
        getList()
    }, [currentPage, pageSize])

    return (
        <>
            {contextHolder}
            <Form
                form={form}
                className={styles.form}
                initialValues={{}}
                layout="inline"
                onValuesChange={(_, values) => {
                    getList(values)
                }}>
                <Form.Item label="标题" name="title">
                    <Input className={styles.inputNumber} placeholder="可输入标题" />
                </Form.Item>
                <Form.Item label="栏目" name="category">
                    <Select className={styles.inputNumber} style={{ width: 180, textAlign: 'left' }} placeholder="请选择分裂" allowClear>
                        <Select.Option value={'inspiration'}>灵感碎片</Select.Option>
                        <Select.Option value={'tools'}>各种工具</Select.Option>
                        <Select.Option value={'study'}>学习资料</Select.Option>
                        <Select.Option value={'recommend'}>个人推荐</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item>
                    <Button
                        style={{ marginRight: 20 }}
                        onClick={() => {
                            form.resetFields()
                        }}>
                        重置
                    </Button>
                    <Button type="primary" onClick={() => detailRef.current.open()}>
                        新建
                    </Button>
                </Form.Item>
            </Form>

            <Table
                rowKey={'id'}
                loading={loading}
                dataSource={dataSource}
                columns={columns}
                pagination={{
                    showSizeChanger: true,
                    showQuickJumper: true,
                    pageSizeOptions: [5, 10, 20, 50],
                    current: currentPage,
                    pageSize: pageSize,
                    total: total,
                    onChange: (current, size) => {
                        setPageSize(size)
                        setCurrentPage(current)
                    }
                }}
            />

            <IndexDetail ref={detailRef} reload={() => getList()} />
        </>
    )
}

export default IndexInclude
